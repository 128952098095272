import React, { useEffect, useLayoutEffect, useState } from "react";
import * as settings from "../../../styles/settings.scss";
import { Formik, useFormik } from "formik";
import * as css from "../../../styles/settings.scss";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { RiArrowLeftLine, RiArrowDownSLine } from "react-icons/ri";
import { FormGroup, FormLabel, Select } from "@mui/material";
import { Checkbox, FormControlLabel, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as user from "./user.scss";
import axios from "axios";
import getTokenFromLocalStorage from "../../../utils/token";
import { useDispatch, useSelector } from "react-redux";

import { successToast } from "../../../responses/successToast";
import { fetchAnnouncementById } from "../../../store/slices/announcementById";
import { editAnnouncementById } from "../../../store/slices/editByIdAnnouncementSlice";
import { fetchUserList } from "../../../store/slices/listAllUser";
import { DatePicker } from "rsuite";
import dayjs from "dayjs";
// Validation schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  system_id: Yup.array()
    .min(1, "Select Systems & Roles To Whom Announcement Display is required")
    .required("System ID is required"),
  display_till: Yup.string().when("display_till_time", {
    is: (display_till_time) => display_till_time !== null, // Check if display_till_time is set
    then: (schema) => schema.required("Until Date is required"), // If set, then display_till is required
    otherwise: (schema) => schema.notRequired(), // If not set, then display_till is not required, can also be null
  }),
  // The array itself must be present
  // roles_id: Yup.array()
  //   .of(
  //     Yup.number()
  //       .integer('Each role_id must be an integer')
  //       .required('Role ID is required')
  //   )
  //   .min(1, 'At least one role_id is required')
  //   .required('Roles ID is required'),
  // display_till:Yup.number().required("Author name is required")
});

const AnnouncementCreate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;
  if (state == null) {
    // console.log("inside state update")
    state = {
      status: 0,
      filterApplied: false,
      filterYear: "",
      filterMonth: { value: "", label: "" },
      filterType: { value: "", label: "" },
      filterPostedBy: { value: "", label: "" },
    };
  }
  const [checkedState, setCheckedState] = useState({});
  const [selectedAppRoles, setSelectedAppRoles] = useState({});
  const [roleErrors, setRoleErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState();
  let [appNames, setAppNames] = useState([]);
  const token = getTokenFromLocalStorage();
  const announcementData = useSelector((state) => {
    return state.announcementById.announcement;
  });
  const authorData = useSelector((state) => {
    return state.userList.userList?.data;
  });
  const formattedAuthorData = authorData?.map((item) => ({
    value: item.id,
    label: item.full_name,
  }));
  const formatYYYYMMDD = (dateToFormat) => {
    // console.log(dateToFormat, "dateToFormat");
    dateToFormat = new Date(dateToFormat);
    const year = dateToFormat?.getFullYear();
    const month = String(dateToFormat?.getMonth() + 1).padStart(2, "0");
    const day = String(dateToFormat?.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const formatTimeFromDayjs = (dayjsObject) => {
    if (dayjsObject?.isValid()) {
      // Check if the dayjs object is valid
      const formattedDate = dayjsObject.format("HH:mm:ss"); // Directly format the time
      return formattedDate;
    } else {
      return null;
    }
  };
  const styles = {
    label: {
      fontWeight: "bold",
      color: "#323232",
      fontSize: "13px",
    },
  };

  const formik = useFormik({
    initialValues: {
      title: announcementData?.data?.title || "",
      description: announcementData?.data?.description || "",
      system_id:
        announcementData?.data?.system_id.map((system) => `${system}`) || [],
      roles_id: announcementData?.data?.system_id.reduce((acc, systemId) => {
        const result = appNames.find((item) => item.id === systemId);
        if (result) {
          const filteredRoles = result.roles.filter((role) =>
            announcementData?.data?.roles_id.includes(role.id)
          );
          const filteredRolesWithId = filteredRoles.map((role) => role.id);
          acc[result.app_name] = filteredRolesWithId;
        }
        return acc;
      }, {}),
      display_till: announcementData?.data?.display_till
        ? new Date(announcementData?.data?.display_till)
            .toISOString()
            .split("T")[0]
        : null,
      display_till_time: announcementData?.data?.display_till
        ? dayjs(announcementData.data.display_till)
            .subtract(5, "hour")
            .subtract(30, "minute")
        : null,
      is_priority: announcementData?.data?.is_priority || false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (announcementData, { setSubmitting }) => {
      setSubmitting(true);
      // console.log("formik values", formik.values);

      if (Object.values(roleErrors).includes(true)) {
        return;
      }
      const dateParams = formik.values.display_till
        ? formatYYYYMMDD(formik.values.display_till)
        : "";
      const timeParams = formik.values.display_till_time
        ? formatTimeFromDayjs(dayjs(formik.values.display_till_time))
        : "";
      const extractValues = (arr) => {
        // Use flatMap to iterate over each object and flatten the arrays into a single array
        return arr.flatMap((obj) => {
          // Get the values of each object (assuming each object has only one key)
          const key = Object.keys(obj)[0];
          return obj[key];
        });
      };

      let isDataChanged =
        formik.initialValues.title !== formik.values.title ||
        formik.initialValues.description !== formik.values.description ||
        formik.initialValues.is_priority !== formik.values.is_priority ||
        formik.initialValues.display_till !== formik.values.display_till ||
        formik.initialValues.display_till_time !==
          formik.values.display_till_time;
      const userData = {
        title: announcementData.title,
        description: announcementData.description,
        system_id: announcementData.system_id.map((system) => parseInt(system)),
        roles_id: Object.values(announcementData.roles_id).flat(),
        display_till: formik.values.display_till
          ? `${dateParams} ${timeParams}`
          : null,
        is_priority: announcementData.is_priority,
        newly_added_systemn: announcementData.system_id
          .map((system) => parseInt(system)) // Ensure both arrays contain numbers
          .filter((system) => !initial.system_id.includes(system)),
        isDataChanged: isDataChanged,

        // Calculate newly added roles
        newly_added_roles: Object.values(announcementData.roles_id)
          .flat()
          .filter((role) => !initial.roles_id.includes(role)),
      };

      dispatch(editAnnouncementById(id, token, userData))
        .then((response) => {
          if (response.success) {
            successToast(response.message);
            navigate("/announcements", {
              state: {
                status: state.status ? state.status : 0,
                filterApplied: state.filterApplied
                  ? state.filterApplied
                  : false,
                filterYear: state.filterYear ? +state.filterYear : "",
                filterMonth: state.filterMonth ? state.filterMonth : "",
                filterType: state.filterType ? state.filterType : "",
                filterPostedBy: state.filterPostedBy
                  ? state.filterPostedBy
                  : "",
                filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                page:state.page?state.page:1,
                rowsPerPage:state.rowsPerPage?state.rowsPerPage:25

              },
            });
          } else {
            console.error("Error creating user:", response.error);
          }
        })
        .catch((error) => {
          console.error("Error creating user:", error.message);
        });

      setSubmitting(false);
    },
  });

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    // error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
  });

  // Handle checkbox state
  // Handle checkbox state
  const handleCheckboxChange = (appName, event, appIndex) => {
    const isChecked = event.target.checked;

    setCheckedState((prevState) => ({
      ...prevState,
      [appName]: { isChecked },
    }));

    // If checkbox is unchecked, clear the selected roles and remove app_name from system_id
    if (!isChecked) {
      setSelectedAppRoles((prevState) => ({
        ...prevState,
        [appName]: [],
      }));

      // Clear the formik field for that app's roles
      formik.setFieldValue(
        "roles_id",
        Object.fromEntries(
          Object.entries(formik.values.roles_id).filter(
            ([key]) => key !== appName
          )
        )
      );

      // Remove app from system_id when unchecked
      formik.setFieldValue(
        "system_id",
        formik.values.system_id.filter((id) => id !== appIndex + 1) // Remove corresponding system ID
      );

      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: false,
      }));
    } else {
      // If checkbox is checked, add app_name to system_id
      formik.setFieldValue("system_id", [
        ...formik.values.system_id,
        appIndex + 1,
      ]);
      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: true,
      }));
    }
  };
  // Handle role selection state
  const handleRoleChange = (appName, event) => {
    formik.setFieldValue("roles_id", {
      ...formik.values.roles_id,
      [event.target.name]: event.target.value, // Use bracket notation to set dynamic key
    });

    // formik.setFieldValue("roles_id", [
    //   ...new Set([...formik.values.roles_id, ...event.target.value])
    // ]);
    setSelectedAppRoles((prevState) => ({
      ...prevState,
      [appName]: event.target.value,
    }));

    // Remove error if a role is selected
    if (event.target.value) {
      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: false,
      }));
    }
    if (event.target.value?.length == 0) {
      setRoleErrors((prevState) => ({
        ...prevState,
        [appName]: true,
      }));
    }
  };

  useLayoutEffect(() => {
    dispatch(fetchAnnouncementById(id, token))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, token]);

  const [role, setRole] = useState([]);
  useEffect(() => {
    if (announcementData?.data) {
      const filteredData = announcementData.data.system_id.reduce(
        (acc, systemId) => {
          const result = appNames.find((item) => item.id === systemId);
          if (result) {
            const filteredRoles = result.roles.filter((role) =>
              announcementData.data.roles_id.includes(role.id)
            );
            const filteredRolesWithId = filteredRoles.map((role) => role.id);
            acc[result.app_name] = filteredRolesWithId;
          }
          return acc;
        },
        {}
      );

      setSelectedAppRoles(filteredData);
      // console.log(announcementData, "announcementData")
      formik.setValues({
        title: announcementData.data.title || "",
        description: announcementData.data.description || "",
        system_id:
          announcementData.data.system_id.map((system) => `${system}`) || [],
        roles_id: filteredData,
        display_till: announcementData.data.display_till
          ? new Date(announcementData.data.display_till)
              .toISOString()
              .split("T")[0]
          : null, // Convert to Date object
        is_priority: announcementData.data.is_priority || false,
        display_till_time: announcementData.data.display_till
          ? dayjs(announcementData.data.display_till)
              .subtract(5, "hour")
              .subtract(30, "minute")
          : null,
      });
      // console.log(formik.values, "announcementData")
      setInitial(announcementData?.data);
      setRole(announcementData.data.roles_id);
    }
  }, [announcementData, appNames]); // Depend on announcementData, not just its existence
  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;

        setAppNames(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useLayoutEffect(() => {
    try {
      dispatch(fetchUserList(token));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }, []);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            onClick={() =>
              navigate("/announcements", {
                state: {
                  status: state.status ? state.status : 0,
                  filterApplied: state.filterApplied
                    ? state.filterApplied
                    : false,
                  filterYear: state.filterYear ? +state.filterYear : "",
                  filterMonth: state.filterMonth ? state.filterMonth : "",
                  filterType: state.filterType ? state.filterType : "",
                  filterPostedBy: state.filterPostedBy
                    ? state.filterPostedBy
                    : "",
                  filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                  page:state.page?state.page:1,
                  rowsPerPage:state.rowsPerPage?state.rowsPerPage:25
                },
              })
            }
            startIcon={<RiArrowLeftLine />}
            disableRipple
            // onClick handler
          ></Button>
          Edit Announcement
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <form onSubmit={formik.handleSubmit}>
          <Box className="card-wrapper">
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginBottom: "16px" }}
                >
                  Announcement Detail
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Title
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      className="input-field"
                      {...getFieldProps({
                        name: "title",
                        required: true,
                        placeholder: "Enter Announcement Title",
                      })}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <FormHelperText className="error-message">
                        {formik.errors.title}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Description
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Box
                      className={`announcement-ck-editor-wrapper ${
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                          ? "has-error"
                          : ""
                      }`}
                      borderRadius={"8px !important"}
                      sx={{ borderColor: "red !important" }}
                    >
                      <CKEditor
                        name="description"
                        data={formik.values.description}
                        editor={ClassicEditor}
                        sx={{ borderColor: "red !important" }}
                        config={{
                          toolbar: [
                            "heading",
                            "bold",
                            "italic",
                            "link",
                            "blockquote",
                            "numberedList",
                            "bulletedList",
                            "indent",
                            "outdent",
                            "undo",
                            "redo",
                          ],
                          placeholder: "Enter Announcement Description",
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          formik.setFieldValue("description", data);
                        }}
                        onBlur={() =>
                          formik.setFieldTouched("description", true)
                        }
                      />
                    </Box>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <FormHelperText className="error-message">
                          {formik.errors.description}
                        </FormHelperText>
                      )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginBottom: "16px" }}
                >
                  Select Systems & Roles To Whom Announcement Display
                </Typography>
                <FormGroup>
                  {appNames.map((app, index) => (
                    <Grid
                      container
                      mb={2}
                      columnSpacing={1}
                      rowSpacing={0}
                      direction="row"
                      alignItems="center"
                      key={app.app_name}
                    >
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <FormGroup
                          name="system_id"
                          value={formik.values.system_id}
                          onChange={(e) => formik.handleChange(e)}
                        >
                          <FormControlLabel
                            value={Number(index + 1)}
                            control={
                              <Checkbox
                                name="system_id"
                                value={Number(index + 1)}
                                // Disable Ripple
                                checked={
                                  formik.values.system_id.includes(
                                    String(index + 1)
                                  ) || false
                                } // Check if the system_id array contains the app's index (as a string)
                                onChange={(event) => {
                                  handleCheckboxChange(
                                    app.app_name,
                                    event,
                                    index
                                  );
                                }}
                              />
                            }
                            label={app.app_name}
                            sx={{ marginRight: "0px !important" }}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <FormControl fullWidth>
                          <Select
                            id={app.app_name}
                            name={app.app_name}
                            // value={Array.from(new Set([
                            //   ...(selectedAppRoles[app.app_name] || []),
                            //   ...(Array.isArray(formik.values.roles_id[app.app_name])
                            //     ? formik.values.roles_id[app.app_name]
                            //     : (Array.isArray(formik.values.roles_id) ? formik.values.roles_id : []) // Ensure fallback is an array
                            //   )
                            // ]))}
                            value={selectedAppRoles[app.app_name] || []}
                            onChange={(event) => {
                              handleRoleChange(app.app_name, event);
                            }}
                            size="small"
                            IconComponent={RiArrowDownSLine}
                            disabled={
                              !formik.values.system_id.includes(
                                String(index + 1)
                              )
                            } // Disable select if the checkbox is not checked
                            error={roleErrors[app.app_name]}
                            multiple
                          >
                            {app.roles.map((role, roleIndex) => (
                              <MenuItem
                                key={roleIndex}
                                value={role.id}
                                name={role.app_name}
                              >
                                {role.role_name}
                              </MenuItem>
                            ))}
                          </Select>
                          {roleErrors[app.app_name] && (
                            <FormHelperText error className="select-error">
                              Please select a role for this app
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  ))}
                </FormGroup>
                {formik.touched.system_id && formik.errors.system_id && (
                  <FormHelperText className="error-message">
                    {formik.errors.system_id}
                  </FormHelperText>
                )}
                <Typography
                  variant="h3"
                  component="h3"
                  // sx={{ marginBottom: "16px" }}
                  sx={{ marginTop: "16px" }}
                >
                  Announcement Display
                </Typography>
                <Grid
                  container
                  gap={1}
                  alignItems={"center"}
                  // justifyContent={"space-between"}
                  sx={{ marginBottom: "24px" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    rowSpacing={0}
                    sx={{
                      alignSelf: "flex-start",
                      paddingTop: {
                        xs: "0px", // Extra small screens
                        sm: "0px", // Small screens
                        md: "0px", // Medium screens
                        lg: "0px", // Large screens
                        xl: "0px", // Extra large screens
                      },
                    }}
                  >
                    <FormControl>
                      {/* <FormLabel></FormLabel> */}
                      {/* <FormLabel>Show In Marquee</FormLabel> */}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="medium"
                              checked={formik.values.is_priority}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "is_priority",
                                  e.target.checked
                                )
                              }
                              color="primary"
                              name="is_priority"
                            />
                          }
                          label={
                            <span style={styles.label}>
                              Highlight Announcement on Dashboard ?
                            </span>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                  {formik.values.is_priority ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={5.9}
                        md={5.9}
                        lg={5.9}
                        sx={{ alignSelf: "flex-start" }}
                      >
                        <FormControl fullWidth>
                          <FormLabel>Until Date</FormLabel>
                          <DatePicker
                            oneTap
                            onBlur={formik.handleBlur}
                            id="app.name"
                            placement="topStart"
                            format="dd-MM-yyyy"
                            name="display_till"
                            placeholder="Enter Date"
                            disabledDate={(date) =>
                              date && date < new Date().setHours(0, 0, 0, 0)
                            }
                            value={
                              formik.values.display_till
                                ? new Date(formik.values.display_till)
                                : null
                            }
                            onChange={(value) => {
                              formik.setFieldValue("display_till", value);
                              if (value == null) {
                                formik.setFieldValue("display_till_time", null);
                              } else {
                                formik.setFieldValue(
                                  "display_till_time",
                                  formik.values.display_till_time
                                    ? formik.values.display_till_time
                                    : new Date().setHours(0, 0)
                                );
                              }
                            }}
                          />
                        </FormControl>

                        {formik.touched.display_till &&
                          formik.errors.display_till && (
                            <FormHelperText className="error-message">
                              {formik.errors.display_till}
                            </FormHelperText>
                          )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={5.9}
                        md={5.9}
                        lg={5.9}
                        sx={{ alignSelf: "flex-start" }}
                      >
                        <FormControl fullWidth>
                          <FormLabel>Until Time</FormLabel>
                          <DatePicker
                            showMeridian
                            onBlur={formik.handleBlur}
                            placement="topStart"
                            name="display_till_time"
                            format="hh:mm aa"
                            placeholder="Select Time"
                            value={
                              formik.values.display_till_time
                                ? new Date(formik.values.display_till_time)
                                : formik.values.display_till
                                ? new Date().setHours(0, 0)
                                : null
                            }
                            onChange={(value) => {
                              if (value === null) {
                                const midnight = new Date();
                                midnight.setHours(0, 0, 0, 0);
                                formik.setFieldValue(
                                  "display_till_time",
                                  midnight
                                );
                              } else {
                                formik.setFieldValue(
                                  "display_till_time",
                                  value
                                );
                              }
                            }}
                            showToday={false}
                            allowClear={true}
                            hideMinutes={(minute) => minute % 5 !== 0}
                          />
                        </FormControl>
                      </Grid>

                      {/* Message beneath the DatePicker components */}
                      <Grid item xs={12}>
                        <Typography
                          component="span"
                          sx={{
                            fontStyle: "italic",
                            color: "#888",
                            fontSize: "0.9em",
                            fontWeight: "bold",
                          }}
                        >
                          ( If date & time not selected, then it will display on
                          Dashboard forever. )
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>

            <Box className="footer-wrapper">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                disableRipple
                type="submit"
                disabled={formik.isSubmitting}
                sx={{ width: "120px" }}
              >
                Save
              </Button>

              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn"
                disableRipple
                onClick={() =>
                  navigate("/announcements", {
                    state: {
                      status: state.status ? state.status : 0,
                      filterApplied: state.filterApplied
                        ? state.filterApplied
                        : false,
                      filterYear: state.filterYear ? +state.filterYear : "",
                      filterMonth: state.filterMonth ? state.filterMonth : "",
                      filterType: state.filterType ? state.filterType : "",
                      filterPostedBy: state.filterPostedBy
                        ? state.filterPostedBy
                        : "",
                      filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                      page:state.page?state.page:1,
                      rowsPerPage:state.rowsPerPage?state.rowsPerPage:25
                    },
                  })
                }
                sx={{ width: "120px" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AnnouncementCreate;
