import { Box, Grid, Typography, Skeleton, Divider } from "@mui/material";
import React, { useState, useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import { RiArrowLeftLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { useDispatch, useSelector } from "react-redux";
// import "./user.scss";
import { fetchAnnouncementById } from "../../../store/slices/announcementById";
import getTokenFromLocalStorage from "../../../utils/token";
import axios from "axios";
import { fetchUserList } from "../../../store/slices/listAllUser";

const Announcementview = () => {
    
  const location = useLocation();

  const token = getTokenFromLocalStorage();
let {state}=location;
if (state == null) {
  // console.log("inside state update")
state = {
    
    status: 0,
      filterApplied:false,
      filterYear:'',
      filterMonth:{ value: '', label: '' },
      filterType:{ value: '', label: '' },
      filterPostedBy:{ value: '', label: ''}
   
};
}
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authorData = useSelector((state) => {
    return state.userList.userList?.data;
  });
  useLayoutEffect(() => {
    try {
      dispatch(fetchUserList(token));
    } catch (error) {
      console.error("Loading Data Failed", error);
    }
  }, []);
  const [displayAnnouncementData,setDisplayAnnouncementData]=useState({
    title:'',
    description:'',
    systemId:[],
    rolesId:[],
    authorId:'',
    isPriority:0,
    applicationDetails:[],
    display_till_date:'',
    display_till_time:''

  })
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  let [appNames,setAppNames]=useState([]);
  const [roleMap,setRoleMap]=useState({})
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
const [mapIdAndRole,setMapIdAndRole]=useState([])

  const announcementData = useSelector((state) => {return state.announcementById.announcement});

useLayoutEffect(() => {
  dispatch(fetchAnnouncementById(id, token))
    .then(() => {
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.error('Error fetching user data:', error);
    });
}, [dispatch, token]);
useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/applications?fields=id,app_name`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.data;
        const mapData = data.map((role)=>role.roles)
        const flatData=mapData.flat()
         setMapIdAndRole (flatData);
        
        setAppNames(data);
       
      } catch (error) {
        console.error("Error fetching data:", error);
       
      }
    };
    fetchData();
  }, []);

useLayoutEffect(() => {
    if (announcementData?.data) {
      const filteredData = announcementData.data.system_id.reduce((acc, systemId) => {
        // Find the app name based on system ID
        const result = appNames.find(item => item.id === systemId);
        
        if (result) {
          // Filter roles based on roles_id
          const filteredRoles = result.roles.filter(role => 
            announcementData.data.roles_id.includes(role.id)
          );
    
          // Extract role IDs
          const filteredRolesWithId = filteredRoles.map(role => role.id);
    
          // Use the app name as the key and assign the array of role IDs as the value
          acc[result.app_name] = filteredRolesWithId;
        }
    
        return acc;
      }, {});
      
    //   setSelectedAppRoles(filteredData)
      
      setDisplayAnnouncementData({
        title:announcementData.data.title || "",
        description:announcementData.data.description || "",
        systemId:announcementData.data.system_id.map((system)=>`${system}`) || [],
        rolesId: filteredData,
        authorId: announcementData?.data?.authorInfo?.first_name+' '+announcementData?.data?.authorInfo?.last_name,
         isPriority: announcementData.data.is_priority || false, 
         applicationDetails:Object.keys(displayAnnouncementData.rolesId) || [],
         display_till_date:announcementData.data.display_till_date,
         display_till_time:announcementData.data.display_till_time
       
      })
      
    //   setRole(announcementData.data.roles_id)
    }
  }, [announcementData,appNames]);

  const userData = useSelector((state) => state.userById.user);
//   const applicationDetails = Object.keys(displayAnnouncementData.rolesId) || [];
  
  const userrole = userData?.data?.type;
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          {" "}
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/announcements",{
                state: {
                  status: state.status?state.status:0,
                  filterApplied:state.filterApplied?state.filterApplied:false,
                  filterYear:state.filterYear?+state.filterYear:'',
                  filterMonth:state.filterMonth?state.filterMonth:'',
                  filterType:state.filterType?state.filterType:'',
                  filterPostedBy:state.filterPostedBy?state.filterPostedBy:'',
                  filterPostedDate:state.filterPostedDate?state.filterPostedDate:'',
                  page:state.page?state.page:1,
                  rowsPerPage:state.rowsPerPage?state.rowsPerPage:25
              },
              })
            }
          ></Button>{" "}
          View Announcement
        </Typography>
      </Box>
      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              variant="div"
              component="div"
              className="appdetail-wrap card-wrapper p-24"
            >
              <Box variant="div" component="div" className="user-detail">
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginBottom: "20px" }}
                >
                  Announcement Detail
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                        Title 
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          {displayAnnouncementData?.title &&
                            capitalizeFirstLetter(displayAnnouncementData?.title)}{" "}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                       Description
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                          style={{
                            height:'fit-content',
                            maxHeight: '200px',         
                            overflowY: 'auto',  
                            textAlign:"justify", 
                            paddingRight:"10px"    
                                     
                          }}
                        >
                         {displayAnnouncementData?.description && parse(displayAnnouncementData?.description)}
                          
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                       Author
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          {displayAnnouncementData?.authorId &&
                            displayAnnouncementData?.authorId}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                 
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                       Highlight as important announcement?
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          
                          {displayAnnouncementData?.isPriority &&
                            displayAnnouncementData?.isPriority===true ? 'Yes':'No'}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                 
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                       Until Date
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          
                          {displayAnnouncementData?.display_till_date ? displayAnnouncementData?.display_till_date : 'N/A'}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                 
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                     Until Time
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          
                          {displayAnnouncementData?.display_till_time ? displayAnnouncementData?.display_till_time : 'N/A'}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                

                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              variant="div"
              component="div"
              className="appdetail-wrap card-wrapper"
            >
              <Box variant="div" component="div" className="app-details">
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginLeft: "24px", paddingTop: "24px" }}
                >
                 Systems & Roles To Whom Announcement Display
                </Typography>
                {loading ? (
                  Array.from({ length: 3 }).map((_, index) => (
                    <Grid
                      container
                      mb={3}
                      mt={1}
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      key={index}
                    >
                      <Grid item xs={12} sm={5} md={5}>
                        <Skeleton variant="rect" width="100%" height={40} />
                      </Grid>
                      <Grid item xs={12} sm={7} md={7}>
                        <Skeleton variant="rect" width="80%" height={40} />
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Grid
  container
  spacing={2}
  mt={1}
  className="app-details-table"
>
<Grid item xs={5} className="application-column">
            <Box variant="div" component="div"   className="header"
                        mb={2}>
              <Typography variant="body1" component="span" className="label" >
                Applications
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7} p={0} className="role-column">
            <Box variant="div" component="div" className="header" >
              <Typography variant="body1" component="span" className="label" >
                Roles
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
          <Divider />
        </Grid> */}
          {Object.keys(displayAnnouncementData?.rolesId).map((app, index) => {
  const roles = Object.values(displayAnnouncementData?.rolesId)[index];
  const rolesMap = roles.map((roleId) => {
    const foundRole = mapIdAndRole.find((roleMap) => roleMap.id === roleId);
    return foundRole ? foundRole.role_name : "Unknown Role"; // Handle case when no match is found
  });

  const isLastElement = index === Object.keys(displayAnnouncementData?.rolesId).length - 1;

  return (
    <>
      <Grid container item xs={12}  key={index} sx={{paddingTop:"0px !important",paddingBottom:"0px !important"}}>
        {/* Application Name */}
        <Grid item xs={5} sx={{borderBottom:!isLastElement?"1px solid #f0f0f0":""}}>
          <Box variant="div" component="div"  className="row">
            <Typography variant="body1" component="p" className="value">
              {app}
            </Typography>
          </Box>
        </Grid>

        {/* Corresponding Roles */}
        <Grid item xs={7} sx={{borderBottom:!isLastElement?"1px solid #f0f0f0":""}}>
          <Box variant="div" component="div" className="row">
            <Typography variant="body1" component="p" className="value">
              {rolesMap.join(", ")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Conditionally render Divider only if it's not the last element */}
      {/* {!isLastElement && (
        <Grid item xs={12} sx={{paddingTop:"0px !important",paddingBottom:"0px !important"}}>
          <Divider />
        </Grid>
      )} */}
    </>
  );
})}

</Grid>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Announcementview;
