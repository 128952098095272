import React, { useState } from 'react'
import { Box, Button, Chip, Collapse, Typography } from '@mui/material'
import ReactQuill from 'react-quill'
import { BiChevronDown } from 'react-icons/bi';
import { RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLocalTimeZone } from '../../utils/timezone';
import axios from 'axios';
import { setFeedback } from '../../store/slices/feedbackSlice';
import Skeleton from "@mui/material/Skeleton";
import { successToast } from '../../responses/successToast';
import { removeReleaseNote } from '../../store/slices/releaseNoteSlice';
import DeleteReleaseNoteModal from '../../common/deleteReleaseNoteModal';
import 'react-quill/dist/quill.bubble.css';

const convertDateIntoDDMMYYYY = (date) => {
    const NewDate = new Date(date);
    const formattedDate = `
        ${NewDate.getDate().toString().padStart(2, '0')}-
        ${(NewDate.getMonth()+1).toString().padStart(2, '0')}-
        ${NewDate.getFullYear().toString().padStart(2, '0')}
    `;
    return formattedDate;
}

const ReleaseNote = ({ id, version, date, app_name }) => {
    const navigate = useNavigate();
    const dispatch =useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState("");

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const handleDeleteModalOpen = () => setIsDeleteModalOpen(true);
    const handleDeleteModalClose = () => setIsDeleteModalOpen(false);
    
    const getReleaseNoteDescription = async () => {
        try {
            if(!isOpen){
                setIsOpen(true);
                setIsLoading(true);
                const paramsData = {
                    id: id,
                    timezone: getLocalTimeZone()
                }
                const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/release-note`, { params: paramsData },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.data.status) {
                    setDescription(response.data.data.description);
                    setIsLoading(false);
                } else {
                    dispatch(
                        setFeedback({
                            status: "fail",
                            message: response.data.message,
                            code: response.status,
                        })
                    );    
                }
            } else {
                setIsOpen(false);
            }
        } catch (error) {
            console.log(error);
            dispatch(
                setFeedback({
                    status: "fail",
                    message: error.response.data.message,
                    code: error.response.status,
                })
            );
        } finally {
            setIsLoading(false);
        }
    }

    const deleteReleaseNote = async () => {
        try {
            const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/release-note/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.data.status) {
                successToast(response.data.message);
                dispatch(removeReleaseNote({ id }));
            } else {
                dispatch(
                    setFeedback({
                        status: "fail",
                        message: response.data.message,
                        code: response.status,
                    })
                );    
            }
            
        } catch (error) {
            dispatch(
                setFeedback({
                    status: "fail",
                    message: error.response.data.message,
                    code: error.response.status,
                })
            );
        }
    }

    return (
        <>
            <Box className="release-note">

                <Box className="release-card-wrapper">
                    <Box className="release-title-wrapper">
                        <Box class="release-title">
                            <Typography variant='p' component={'p'} className="application-name-text">{app_name}</Typography>
                            <Chip
                                label={`Version ${version.replace("v", "")}`}
                                sx={{
                                    backgroundColor: 'var(--green)',
                                    color: 'white !important',
                                    fontWeight: 'semibold',
                                    marginLeft: 1
                                }}
                                size="small"
                            />
                        </Box>
                        <Typography variant='p' component={'p'} className="date-text">{convertDateIntoDDMMYYYY(date)}</Typography>
                    </Box>
                    <Box className="release-action-container">

                        <Box className="action-btn-wrap">
                            <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiPencilLine />}
                                disableRipple
                                title="Edit"
                                onClick={() => navigate(`/release-notes/${id}/edit`)}
                            ></Button>

                            <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                title="Delete"
                                onClick={handleDeleteModalOpen}
                            ></Button>
                        </Box>
                        <BiChevronDown 
                            onClick={getReleaseNoteDescription} 
                            className={'down-arrow rotate-transition'} 
                            style={{ transform: `rotate(${isOpen ? '180deg' : '0deg'})` }} 
                        />
                    </Box>
                </Box>               
                
                <Collapse in={isOpen} timeout="auto" unmountOnExit={true}> 
                    {
                        isLoading ? (
                            <Skeleton variant="rect" width="100%" height={150} />
                        ) : (
                        <Box className="description-content">
                            <ReactQuill 
                                value={description || "<h4>NO NOTES AVAILABLE</h4>"}
                                theme='bubble'
                                readOnly
                            />
                        </Box>
                        )
                    }
                </Collapse>
            </Box>

            {isDeleteModalOpen && <DeleteReleaseNoteModal 
                isDeleteModalOpen={isDeleteModalOpen} 
                handleDeleteModalClose={handleDeleteModalClose} 
                deleteReleaseNoteHandler={deleteReleaseNote}
            />}
        </>
    )
}

export default ReleaseNote