/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import announcementSetting from "../../assets/images/announcementSetting.svg"
const Settings = () => {
    const navigate = useNavigate();

    return (
        <>
            <Box className="content-header project-list-header">
                <Typography variant="h2" component="h2">
                    Settings
                </Typography>
            </Box>
            <Box className="content-layout settings-layout p-24">
                <Grid container spacing={3}>
                    <Grid item lg={3} ms={3} sm={6} xs={12}>
                        <Box variant="div" component="div" className="report-card-wrapper" onClick={() => navigate('/announcements-settings')}>
                            <Box variant="div" component="div" className="app-logo">
                                <img src={announcementSetting} alt="app-logo" loading="lazy" />
                            </Box>
                            <Typography className='card-name' variant='h5' component='h5'>
                                Announcements
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Settings;
