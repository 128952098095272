import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  announcement: null,
  loading: false,
  error: null,
};

const announcementByIdSlice = createSlice({
  name: "announcementById",
  initialState,
  reducers: {
    getAnnouncementByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    getAnnouncementByIdSuccess(state, action) {
      state.loading = false;
      state.announcement = action.payload;
    },
    getAnnouncementByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getAnnouncementByIdStart, getAnnouncementByIdSuccess, getAnnouncementByIdFailure } =
  announcementByIdSlice.actions;
export default announcementByIdSlice.reducer;

export const fetchAnnouncementById = (announcementId, token) => async (dispatch) => {
  try {
    dispatch(getAnnouncementByIdStart());

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/announcement/${announcementId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const announcement = response.data; // Assuming the user data is in the response's "data" property
    dispatch(getAnnouncementByIdSuccess(announcement));
  } catch (error) {
    dispatch(getAnnouncementByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
  }
};
