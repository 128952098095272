import { Box, Grid, Typography, Skeleton, Divider } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { RiArrowLeftLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchUserById } from "../../store/slices/userByIdSlice";
import getTokenFromLocalStorage from "../../utils/token";
import { useDispatch, useSelector } from "react-redux";
import "./user.scss";

const Userview = () => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      rowsPerPage: 10,
      search: "",
      company: "1",
      currentPage: 1
    };
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const token = getTokenFromLocalStorage();
    dispatch(fetchUserById(userId, token))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, userId]);

  const userData = useSelector((state) => state.userById.user);
  const applicationDetails = userData?.data?.application_details || [];
  const userrole = userData?.data?.type;
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          {" "}
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/users", {
                state: {
                  status: state.status,
                  search: state.search,
                  rowsPerPage: state.rowsPerPage,
                  company: state.company,
                  currentPage: state.currentPage
                },
              })
            }
          ></Button>{" "}
          View User
        </Typography>
      </Box>
      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              variant="div"
              component="div"
              className="appdetail-wrap card-wrapper p-24"
            >
              <Box variant="div" component="div" className="user-detail">
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ marginBottom: "20px" }}
                >
                  User Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                        Type
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          {userData?.data?.type &&
                            capitalizeFirstLetter(userData?.data?.type)}{" "}
                          User
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  {userrole === "internal" && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="span"
                            className="label"
                          >
                            Company
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={100} height={30} />
                          ) : (
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {userData?.data?.company_name
                                ? capitalizeFirstLetter(
                                  userData?.data?.company_name
                                )
                                : "-"}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                          },
                        }}
                      >
                        <Divider className="divider" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="span"
                            className="label"
                          >
                            Employee Id
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={100} height={30} />
                          ) : (
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {userData?.data?.employee_number}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider className="divider" />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                        First Name
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          {userData?.data?.first_name &&
                            capitalizeFirstLetter(userData?.data?.first_name)}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  >
                    <Divider className="divider" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                        Last Name
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          {userData?.data?.last_name &&
                            capitalizeFirstLetter(userData?.data?.last_name)}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                        Contact Number
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="value"
                        >
                          {userData?.data?.contact_number}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  >
                    <Divider className="divider" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box variant="div" component="div">
                      <Typography
                        variant="body1"
                        component="span"
                        className="label"
                      >
                        Email
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                            className="value"
                            style={{wordWrap:'break-word'}}
                        >
                          {userData?.data?.email}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid>
                  {userrole === "internal" && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="span"
                            className="label"
                          >
                            Designation
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={100} height={30} />
                          ) : (
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {userData?.data?.designation_name}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{
                          display: {
                            xs: "block",
                            sm: "block",
                            md: "none",
                            lg: "none",
                          },
                        }}
                      >
                        <Divider className="divider" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="span"
                            className="label"
                          >
                            Role
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={100} height={30} />
                          ) : (
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {userData?.data?.role &&
                                capitalizeFirstLetter(userData?.data?.role)}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider className="divider" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box variant="div" component="div">
                          <Typography
                            variant="body1"
                            component="span"
                            className="label"
                          >
                            Status
                          </Typography>
                          {loading ? (
                            <Skeleton variant="text" width={100} height={30} />
                          ) : (
                            <Typography
                              variant="body1"
                              component="p"
                              className="value"
                            >
                              {userData?.data?.status &&
                                capitalizeFirstLetter(userData?.data?.status)}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              variant="div"
              component="div"
              className="appdetail-wrap card-wrapper"
            >
              <Box variant="div" component="div" className="app-details">
                <Typography
                  variant="h3"
                  component="h3"
                // sx={{ marginLeft: "24px", paddingTop: "24px" }}
                >
                  Application Details (Assigned Applications & Roles)
                </Typography>
                {loading ? (
                  Array.from({ length: 3 }).map((_, index) => (
                    <Grid
                      container
                      // mb={3}
                      mt={1}
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      key={index}
                    >
                      <Grid item xs={12} sm={5} md={5}>
                        <Skeleton variant="rect" width="100%" height={40} />
                      </Grid>
                      <Grid item xs={12} sm={7} md={7}>
                        <Skeleton variant="rect" width="80%" height={40} />
                      </Grid>
                    </Grid>
                  ))
                ) : applicationDetails.length === 0 ? (
                  <Typography
                    variant="body1"
                    component="p"
                    style={{ marginLeft: "100px" }}
                  >
                    No applications/roles assigned
                  </Typography>
                ) : (
                  <Grid
                    container
                    spacing={2}
                    mt={1}
                    className="app-details-table"
                  >
                    <Grid item xs={6} className="application-column">
                      <Box
                        variant="div"
                        component="div"
                        className="header"
                        // mb={2}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className="label"

                        >
                          Applications
                        </Typography>
                      </Box>
                      {applicationDetails.map((app) => (
                        <Box variant="div" component="div" className="row">
                          <Typography
                            variant="body1"
                            component="p"
                            className="value"
                          >
                            {app.application_name}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                    <Grid item xs={6} p={0} className="role-column">
                      <Box
                        variant="div"
                        component="div"
                        className="header"
                        mb={2}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className="label"
                        >
                          Roles
                        </Typography>
                      </Box>
                      {applicationDetails.map((app) => (
                        <Box variant="div" component="div" className="row">
                          <Typography
                            variant="body1"
                            component="p"
                            className="value"
                          >
                            {app.role_name}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Userview;
