import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
} from "@mui/material";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import {
  deleteAnnouncementById,
  fetchAnnouncementList,
} from "../store/slices/announcementFetchDelete";
import DeleteModalAnnouncement from "./deleteAnnouncement";
import "../pages/settings/PostAnnouncements/user.scss";
import { fetchUserById } from "../store/slices/userByIdSlice";
import getTokenFromLocalStorage from "../utils/token";
import getIdFromLocalStorage from "../utils/userid";
import { boolean } from "yup";
import { CustomTablePaginationToolbar } from "./Pagination";
const AnnouncementCard = ({
  title,
  status,
  date,
  description,
  id,
  is_priority,
  sys_name,
  author_name,
  display_till,
  filterApplied,
  filterYear,
  filterMonthNavigation,
  filterTypeNavigation,
  filterPostedByNavigation,
  time,
  searchFilterText,
  dateRange,
  dateFilter,
  page,
  rowsPerPage
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // const isMobile = useMediaQuery("(max-width:700px)");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // const userId = getIdFromLocalStorage();
  const token = getTokenFromLocalStorage();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    // setSelectedAnnouncement(null);
  };

  const handleDelete = (id) => {
    dispatch(deleteAnnouncementById(id, token));
    handleCloseMenu();
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };
  // console.log(dateRange,"dateRange")
  return (
    <>
      {/* <Box padding="16px !important" sx={{ position: 'relative' }}> */}

      <TableRow key={id}>
        <TableCell
          align="left"
          // Reduced width and minWidth
        >
          <Tooltip title={title} arrow>
            <span style={{ cursor: "pointer" }}>{truncateText(title, 25)}</span>
          </Tooltip>
        </TableCell>
        <TableCell align="left">
          <Box className="app-list">
            <Box variant="div" component="div" className="tag-wrap">
              {sys_name?.map(
                (application, index) =>
                  index < 1 && <tag key={index}>{application}</tag>
              )}
            </Box>
            {sys_name?.length > 1 && (
              <Typography variant="body1" component="p" className="plus-users">
                +
                <Typography
                  variant="body1"
                  component="span"
                  className="plus-users"
                >
                  {sys_name?.length - 1}
                </Typography>{" "}
                more
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell align="left">{author_name}</TableCell>
        {/* <TableCell
        align="left"
        sx={{ width: "20%", maxWidth: "20%", minWidth: "150px" }}
      >
        {date}
      </TableCell> */}
        <TableCell align="left">{date}</TableCell>
        <TableCell align="left">{is_priority ? "Yes" : "No"}</TableCell>
        <TableCell align="left">
          {}
          {display_till === "N/A" ? (
            is_priority ? (
              "Always"
            ) : (
              "N/A"
            )
          ) : (
            <>
              {display_till.date} {time.replace(" ", "")}
            </>
          )}
        </TableCell>

        <TableCell align="left">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              gap: "8px",
            }}
          >
            <Button
              variant="outlined"
              className="action-btn view-btn"
              startIcon={<RiEyeLine />}
              disableRipple
              onClick={() =>
                navigate(`/view-announcements/${id}`, {
                  state: {
                    status: status,
                    filterApplied: filterApplied,
                    filterYear: filterYear,
                    filterMonth: filterMonthNavigation,
                    filterType: filterTypeNavigation,
                    filterPostedBy: filterPostedByNavigation,
                    filterPostedDate: dateFilter,
                    page:page,
                    rowsPerPage:rowsPerPage
                  },
                })
              }
              title="View"
            />
            <Button
              variant="outlined"
              className="action-btn edit-btn"
              startIcon={<RiPencilLine />}
              disableRipple
              onClick={() =>
                navigate(`/edit-announcements/${id}`, {
                  state: {
                    status: status,
                    filterApplied: filterApplied,
                    filterYear: filterYear,
                    filterMonth: filterMonthNavigation,
                    filterType: filterTypeNavigation,
                    filterPostedBy: filterPostedByNavigation,
                    filterPostedDate: dateFilter,
                    page:page,
                    rowsPerPage:rowsPerPage
                    
                  },
                })
              }
              title="Edit"
            />
            <Button
              variant="outlined"
              className="action-btn dlt-btn"
              startIcon={<RiDeleteBinLine />}
              disableRipple
              onClick={() => setOpen(true)}
              title="Delete"
            />
          </Box>
        </TableCell>
      </TableRow>

      <DeleteModalAnnouncement
        open={open}
        handleClose={handleCloseModal}
        announcementId={id}
        handleDeleteAnnouncement={handleDelete}
      />
    </>
  );
};

const Announcements = ({
  filterYear,
  filterMonth,
  filterPostedBy,
  filterType,
  searchText,
  setSearchText,
  isAppend,
  setIsAppend,
  status,
  setStatus,
  filterMonthNavigation,
  filterTypeNavigation,
  filterPostedByNavigation,
  filterApplied,
  searchFilterText,
  dateRange,
  dateFilter,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  // const [page, setPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false); // Track if filtering is active
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  const announcementList = useSelector((state) => {
    return state.announcementList.announcementList;
  });
  const announcementListCount = useSelector((state) => {
    return state.announcementList.count;
  });
  // console.log(announcementList, "announcementList");
  const userId = getIdFromLocalStorage();
  const appendRef = useRef();

  const [loading, setLoading] = useState(true);
  const [debouncedTerm, setDebouncedTerm] = useState(null);

  let scrollRef = useRef();

  const formatDate = (isoDateString) => {
    // Create a Date object from the ISO date string
    const date = new Date(isoDateString);

    // Get day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Pad with 0 if day < 10
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    // Return formatted date as 'DD-MM-YYYY'
    return `${day}-${month}-${year}`;
  };
  function formatTo12HourTime(dateString) {
    const date = new Date(dateString);

    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }
  const extractTextFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="user-name">
          {role}
        </Typography>{" "}
      </Box>
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    if (!boolean(searchText)) {
      setDebouncedTerm((value) => value);
    }
    const handler = setTimeout(() => {
      setDebouncedTerm(searchText); // Update the debounced term after 500ms delay
    }, 500);

    return () => {
      clearTimeout(handler); // Clear timeout when searchText changes to prevent extra API calls
    };
  }, [searchText]);
  useLayoutEffect(() => {
    // const token = getTokenFromLocalStorage();
    dispatch(fetchUserById(userId, token))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, userId]);
  const userDataById = useSelector((state) => {
    return state.userById.user;
  });

  useLayoutEffect(() => {
    // setIsAppend(false)
    appendRef.current = false;
  }, [
    filterYear,
    filterMonth,
    filterPostedBy,
    filterType,
    searchFilterText,
    dateRange,
    isFiltering,
    debouncedTerm,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          fetchAnnouncementList(
            token,
            filterYear,
            filterMonth,

            filterPostedBy,
            filterType,
            debouncedTerm,
            // isFiltering,
            // debouncedTerm,
            page,
            isFiltering,
            isAppend,
            setIsAppend,
            status,
            appendRef,
            searchFilterText,
            dateRange,
            rowsPerPage
          )
        );

        if (
          (response < 20 && appendRef.current) ||
          (Boolean(debouncedTerm) && response < 20)
        ) {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error loading announcements:", error);
      }
    };

    // if (isFiltering) {
    //   // Fetch data if filtering is active
    //   fetchData();
    // } else

    if (hasMore || !appendRef.current) {
      // Fetch normal data if not filtering
      fetchData();
    }
  }, [
    filterYear,
    filterMonth,
    filterPostedBy,
    isFiltering,
    debouncedTerm,
    filterType,
    page,
    rowsPerPage,
    searchFilterText,
    dateRange,
    dispatch,
    token,
    debouncedTerm,
  ]);

  // filterYear, filterMonth, filterPostedBy, filterType,
  useEffect(() => {
    setDebouncedTerm("");
    setSearchText("");
  }, [status]);
  // useEffect(() => {
  //   // console.log("inside clear filter");
  //   setHasMore(true); // Reset hasMore for filtered data
  //   setPage(1);
  //   // setIsAppend(false);
  //   appendRef.current = false;
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollTop = 0;
  //   }
  // }, [
  //   filterYear,
  //   filterMonth,
  //   isFiltering,
  //   searchFilterText,
  //   dateRange,
  //   debouncedTerm,
  //   filterPostedBy,
  //   filterType,
  //   debouncedTerm,
  //   status,
  // ]);

  useEffect(() => {
    if (userDataById?.data?.allow_announcements) {
      if (
        filterYear ||
        filterMonth ||
        filterType ||
        dateRange ||
        searchFilterText
      ) {
        setIsFiltering(true);
      } else {
        setIsFiltering(false);
      }
    } else {
      if (
        filterYear ||
        filterMonth ||
        filterPostedBy ||
        filterType ||
        dateRange ||
        searchFilterText
      ) {
        setIsFiltering(true);
      } else {
        setIsFiltering(false);
      }
    }
  }, [
    filterYear,
    filterMonth,
    filterPostedBy,
    filterType,
    debouncedTerm,
    dateRange,
    searchFilterText,
  ]);

  const handleScroll = debounce((e) => {
    const container = e.target;
    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 3 &&
      hasMore
    ) {
      appendRef.current = true;
      setPage((prevPage) => prevPage + 1);
    }
  }, 100); // Debounce by 100ms
  const [value, setValue] = useState(0);
  const announcements =
    announcementList?.map((dat) => ({
      id: dat.id,
      title: dat.title,
      date: formatDate(dat.announcement_date),
      time: dat?.time,
      display_till: dat.display_till
        ? {
            date: formatDate(dat.display_till),
            time: formatTo12HourTime(dat.display_till),
          }
        : "N/A",
      description: extractTextFromHTML(dat.description),
      is_priority: dat.is_priority,
      sys_name: dat.sys_name,
      author_name: `${dat.authorInfo.first_name} ${dat.authorInfo.last_name} `,
    })) || [];
  const handleChange = (event, newValue) => {
    setStatus(newValue);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      {/* <Box
        variant="div"
        component="div"
        className="tab-wrapper user-listing card-wrapper"
        sx={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          padding: "0px",
          
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={status}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area"
          >
            <Tab
              label={tabLabel("Active")}
              {...a11yProps(0)}
              sx={{ padding: "12px 16px" }}
            />
            <Tab
              label={tabLabel("Past")}
              {...a11yProps(1)}
              sx={{ padding: "12px 16px" }}
            />
          </Tabs>
        </Box>
      </Box> */}

      <Box
        className="tab-wrapper user-listing card-wrapper"
        sx={{
          // height: "70vh",
          // maxHeight: "70vh",
          overflowY: "auto",
          width: "100%",
          backgroundColor: "#f5f5f5",
          padding: "0px !important",
          // paddingBottom: {
          //   xs: "10px !important",  // Smaller padding for extra-small devices (mobile)
          //   sm: "10px !important",            // Medium padding for small devices (tablets)
          //   md: "0px !important",            // Larger padding for medium devices (laptops)
          //   lg: "0px !important",            // Even larger padding for large devices (desktops)
          // },
          // paddingRight: {
          //   xs: "0.6px !important",  // Smaller padding for extra-small devices (mobile)
          //   sm: "0.6px !important",            // Medium padding for small devices (tablets)
          //   md: "0px !important",            // Larger padding for medium devices (laptops)
          //   lg: "0px !important",            // Even larger padding for large devices (desktops)
          // },
          borderTopLeftRadius: "12px !important",
          borderTopRightRadius: "12px !important",
          borderBottomLeftRadius: "12px  !important",
          borderBottomRightRadius: "12px !important",
        }}
      >
        {/* This Box acts as a scrollable container */}
        <TableContainer
        // sx={{
        //   // maxHeight: "100%", // Ensures the table container stays within the defined height
        //   // overflowY: "auto", // Allows vertical scrolling
        //   // overflowX:"auto",
        //   // minWidth: '650' ,
        //   height:"100%",
        //   borderBottomRightRadius:"12px",
        //   borderBottomLeftRadius:"12px",
        //   borderTopRightRadius:"12px",
        //   borderTopLeftRadius:"12px",
        //   // paddingBottom:"10px",

        // }}
        // ref={scrollRef}
        // // onScroll={handleScroll}
        >
          <Table
            aria-label="table"
            className="listing-table"
            sx={{ minWidth: 1000 }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Applications</TableCell>
                <TableCell align="left">Posted By</TableCell>
                <TableCell align="left">Posted Date</TableCell>
                {/* <TableCell align="left" sx={{ width: "40%", maxWidth: "40%", minWidth: "150px" }}> Display Till</TableCell> */}
                <TableCell align="left"> Highlighted</TableCell>
                <TableCell align="left">Highlighted Till</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>

            {announcements.length > 0 ? (
              announcements.map((announcement, index) => (
                <TableBody sx={{ backgroundColor: "white", width: "100%" }}>
                  <AnnouncementCard
                    key={index}
                    title={announcement.title}
                    date={announcement.date}
                    display_till={announcement.display_till}
                    description={announcement.description}
                    is_priority={announcement.is_priority}
                    id={announcement.id}
                    status={status}
                    sys_name={announcement?.sys_name}
                    author_name={announcement?.author_name}
                    filterMonthNavigation={filterMonthNavigation}
                    filterTypeNavigation={filterTypeNavigation}
                    filterPostedByNavigation={filterPostedByNavigation}
                    filterApplied={filterApplied}
                    filterYear={filterYear}
                    time={announcement?.time}
                    searchFilterText={searchFilterText}
                    dateRange={dateRange}
                    dateFilter={dateFilter}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                </TableBody>
              ))
            ) : (
              <TableBody sx={{ backgroundColor: "white", width: "100%" }}>
              
              <TableRow>
                <TableCell colSpan={7}>
                  <Typography
                    variant="body1"
                    component="div"
                    className="no-msg"
                  >
                    No Announcements Found
                  </Typography>
                </TableCell>
              </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <CustomTablePaginationToolbar
          count={announcementListCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default Announcements;
