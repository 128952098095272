import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  announcementList: [], // Array to hold multiple announcements
  loading: false,
  error: null,
  count:0
};

const announcementList = createSlice({
  name: "announcementList",
  initialState,
  reducers: {
    announcementListStart(state) {
      state.loading = true;
      state.error = null;
    },
    announcementListSuccess(state, action) {
      state.loading = false;
      // Append new announcements to the existing list
    // console.log("action.payload",action.payload)
        if(action.payload.appendRef.current)
          
        {
          state.announcementList = [...state.announcementList, ...action.payload.data.allAnnouncements];
          state.count=action.payload.data.count
          
        }
        else{
          // action.payload.setIsAppend(true);
        
          state.announcementList = [...action.payload.data.allAnnouncements];
          state.count=action.payload.data.count
        }
    
        // state.announcementList = [...state.announcementList, ...action.payload.data];
        
     
    },
    announcementListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    announcementDeleteStart(state) {
      state.loading = true;
      state.error = null;
    },
    announcementClear(state){
      state.announcementList=[]
      // state.loading = true;
      // state.error = null;
    },
    announcementDeleteSuccess(state, action) {
      state.loading = false;
      // Remove the deleted announcement from the list
      state.announcementList = state.announcementList.filter(
        announcement => announcement.id !== action.payload // Assuming action.payload contains the deleted announcement's ID
      );
    },
    announcementDeleteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  announcementListStart,
  announcementListSuccess,
  announcementListFailure,
  announcementDeleteStart,
  announcementDeleteSuccess,
  announcementDeleteFailure,
  announcementClear,
} = announcementList.actions;

export default announcementList.reducer;

export const fetchAnnouncementList = (token, filterYear, filterMonth, filterPostedBy,filterType, searchText, page,isFiltering,isAppend,setIsAppend,status,appendRef, searchFilterText,
  dateRange,rowsPerPage) => async (dispatch) => {
  try {
    // if(filterYear||filterYear|| filterMonth|| filterPostedBy|| filterType){
    //   dispatch(announcementClear())
    // }
  

    dispatch(announcementListStart());
  
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/announcement`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          selected_year: filterYear,
          selected_month: filterMonth,
          posted_by: filterPostedBy,
          type: filterType,
          limit: 20,
          page: page,
          searchText:searchText,  // Pass the page number for pagination
          // Status:status==0?'active':'passed',
          searchFilterText:searchFilterText,
          dateRange:dateRange,
          limit:rowsPerPage
        }
      }
    );
    
    const announcements = response.data.data; // Assuming the announcements are in the response's "data" property
    // console.log(response.data.data,'response.data.data')
    // Dispatch success action with the announcements
    dispatch(announcementListSuccess({data:announcements,isFiltering:isFiltering,isAppend:isAppend,setIsAppend:setIsAppend,appendRef:appendRef,searchText:searchText}));

    // Return the length of the response data
    return announcements.length;
  } catch (error) {
    dispatch(announcementListFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );

    // Optionally return 0 or -1 if there is an error
    return 0;
  }
};


export const deleteAnnouncementById = (announcementId, token) => async (dispatch) => {
  try {
    dispatch(announcementDeleteStart());
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/announcement/${announcementId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Dispatch success with the ID of the deleted announcement
    dispatch(announcementDeleteSuccess(announcementId));
  } catch (error) {
    dispatch(announcementDeleteFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};
