// slices/userSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getTokenFromLocalStorage from "../../utils/token";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  users: [],
  status: "idle",
  error: null,
};

export const fetchUsersByType = createAsyncThunk(
  "users/fetchUsersByType",
  async ({ userType, page, searchQuery, status, company,rowsPerPage }, { dispatch }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users?fields=id,first_name,last_name,status,email,application_details,type,employee_number,designation,company,allow_announcements`,
        {
          params: {
            query: searchQuery,
            // type: userType,
            ...(status !== "archive" && { type: userType }),
            page: page,
            status: status,
            company,
            limit:rowsPerPage
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return Promise.reject(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersByType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersByType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("users/fetchUsersByType/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export const { setUsers } = userSlice.actions;

export default userSlice.reducer;
