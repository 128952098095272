import React, { useEffect, useLayoutEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "./header/header";
import SideMenu from "./sideMenu/sideMenu";
import PatternBig from "../assets/images/Big-Ellipse.png";
import PatternSmall from "../assets/images/Small-Ellipse.png";
import getuserDataFromCookies from "../utils/userData";
import { useDispatch, useSelector } from "react-redux";
import { handleSidebar } from "../store/slices/drawerHandlerSlice";
import getTokenFromLocalStorage from "../utils/token";
import getIdFromLocalStorage from "../utils/userid"; 
import { fetchUserById } from "../store/slices/userByIdSlice";
const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const userData = getuserDataFromCookies();
  const userId = getIdFromLocalStorage();
  const token = getTokenFromLocalStorage();

  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch();
//  const [isAdmin /*setIsAdmin*/] = useState(true);

 const [isAdmin ,setIsAdmin] = useState(userData?.role === "admin");
 const [isAnnouncementAllowed,SetIsAnnouncementAllowed]=useState(false)
 let [isHrmanager,setIsHrmanager] = useState(userData?.app_roles?.HRMS =='HR Manager')
 let [isHrexecutive,setIsHrexecutive] = useState(userData?.app_roles?.HRMS =='HR Executive')
 const [openSideMenu, setOpenSideMenu] = useState(
  isAdmin||isHrmanager||isHrexecutive ? sidebarState : false
);
 useEffect(()=>{
  setIsAdmin(userData?.role === "admin");
  setIsHrmanager(userData?.app_roles?.HRMS =='HR Manager');
  setIsHrexecutive(userData?.app_roles?.HRMS =="HR Executive")
 },[userData])
 useEffect(()=>{
setOpenSideMenu( isAdmin||isHrmanager||isHrexecutive ? sidebarState : false)
 },[isAdmin,isHrmanager,isHrexecutive])

  const isMobile = useMediaQuery("(max-width:991.98px)");
  const handleDrawerClose = () => {
    dispatch(handleSidebar(false));
  };
  useLayoutEffect(() => {
    // const token = getTokenFromLocalStorage();
    dispatch(fetchUserById(userId, token))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, userId]);
  const userDataById = useSelector((state) => {return state.userById.user});
  
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 992px)");

    const handleMediaQueryChange = (event) => {
      dispatch(handleSidebar(event.matches));
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  useLayoutEffect(() => {
    function handleSideMenu() {
      setOpenSideMenu(window.innerWidth < 767 ? false : true);
    }
    window.addEventListener("resize", handleSideMenu);
    handleSideMenu();
    return () => window.removeEventListener("resize", handleSideMenu);
  }, []);

  return (
    <Box>
      <Header
        setOpenSideMenu={setOpenSideMenu}
        handleDrawerClose={handleDrawerClose}
        openSideMenu={openSideMenu}
        isAdmin={isAdmin}
        isMobile={isMobile}
        isHrmanager={isHrmanager}
          isHrexecutive={isHrexecutive}
          isAllowed={userDataById?.data?.allow_announcements? userDataById?.data?.allow_announcements : false}
      />
      {isAdmin || isHrexecutive || isHrmanager ||userDataById?.data?.allow_announcements ? (
        <SideMenu
          openSideMenu={openSideMenu}
          setOpenSideMenu={setOpenSideMenu}
          isMobile={isMobile}
          handleDrawerClose={handleDrawerClose}
          isAdmin={isAdmin}
          isHrmanager={isHrmanager}
          isHrexecutive={isHrexecutive}
          isAllowed={userDataById?.data?.allow_announcements?userDataById?.data?.allow_announcements : false}
        />
      ) : null}

      <main
        className={`main-content ${sidebarState ? "" : "full-width"}  ${
          isAdmin||isHrexecutive||isHrmanager||userDataById?.data?.allow_announcements? "admin" : "non-admin"
        } `}
      >
        {children}
        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </main>
    </Box>
  );
};

export default Layout;
