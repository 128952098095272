import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createAnnouncementSlice = createSlice({
  name: "createAnnouncement",
  initialState,
  reducers: {
    createAnnouncementStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createAnnouncementSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createAnnouncementFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createAnnouncementStart, createAnnouncementSuccess, createAnnouncementFailure } =
  createAnnouncementSlice.actions;

export default createAnnouncementSlice.reducer;

export const createAnnouncement = (AnnouncementData, token) => async (dispatch) => {
  try {
    dispatch(createAnnouncementStart());

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/announcement`,
      AnnouncementData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = response.data;
    dispatch(createAnnouncementSuccess());
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createAnnouncementFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
