import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  announcement: null,
  loading: false,
  error: null,
};

const editByIdAnnouncementSlice = createSlice({
  name: "editAnnouncement",
  initialState,
  reducers: {
    editAnnouncementByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editAnnouncementByIdSuccess(state, action) {
      state.loading = false;
      state.announcement = action.payload;
    },
    editAnnouncementByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { editAnnouncementByIdStart, editAnnouncementByIdSuccess, editAnnouncementByIdFailure } =
editByIdAnnouncementSlice.actions;
export default editByIdAnnouncementSlice.reducer;

export const editAnnouncementById = (announcementId, token, editData) => async (dispatch) => {
  try {
    dispatch(editAnnouncementByIdStart());
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/announcement/${announcementId}`,
      editData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const announcement = response;
    const data = response.data;
    dispatch(editAnnouncementByIdSuccess(announcement));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(editAnnouncementByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
