import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

function capitalizeMessage(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const errorToast = (message) => {
    toast.error(capitalizeMessage(message), {
        position: "bottom-left",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className:"toast"
    });
}